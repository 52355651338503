.container {
  max-width: 700px;
  margin: 0 auto;
  border: solid 1px #f2f2f2;
}

@media (max-width: 520px) {
  .wrapGraphicsCalcucator {
    flex-direction: column !important;

    .item,
    .item_first {
      max-width: 100% !important;
    }
    .item_first {
      border-bottom: solid 1px #f2f2f2;
    }
  }
}
