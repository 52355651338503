.rounded {
  position: relative;

  .MuiInput-root {
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 11px 29px 13px 9px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #29173B;

    &.Mui-focused {
      border-color: #29173B;
    }

    &.Mui-error {
      border-color: #C86B6D;
    }

    &:after, &:before {
      display: none;
    }


  }

  .base-input--error-text {
    position: absolute;
    left: 0;
    bottom: 0;

  }
}
