.container {
  .formContainer {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 21px 20px 24px 20px;
  }

  .logoContainer {
    padding: 13px 0 24px;
    display: flex;
    justify-content: center;
  }
}
