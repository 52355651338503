.rounded.MuiFormControl-root {
  .MuiOutlinedInput-root {
    background: #F2F2F2;
    border-radius: 44px;
    border: 1px solid transparent;

    &.Mui-focused {
      border-color: #29173B;
    }
  }
}
