.container {
  min-height: 100vh;
  background: top / cover url('/assets/images/background/SignUp-background.svg') no-repeat #E5E5E5;
  overflow: hidden;

  .logoContainer {
    padding: 13px 0 24px;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;

    &.scrolled {
      background: #F2F2F2;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .MuiGrid-container {
    margin: 0;
  }
}
