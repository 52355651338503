.card,
.cardPartner {
  height: 100%;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 3px 2px 1px rgb(0 0 0 / 20%), 0px 2px 2px 1px rgb(0 0 0 / 14%),
      1px 1px 3px 0px rgb(0 0 0 / 12%);
  }
}

.cardPartner {
  width: 100%;
}
