.container {
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.bottomNavigationContainer {
  background: #ffffff;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 30px 30px 0px 0px;
  height: 83px !important;

  span {
    text-transform: none;
    font-weight: 500;
  }

  .Mui-selected {
    path {
      fill: red;
    }
  }
}
