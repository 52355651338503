.PhoneInputCountryIcon {
  border-radius: 50px;
  height: 24px;
  width: 24px;
  box-shadow: none !important;
  border: none;
  background-color: transparent;
  overflow: hidden;

  &:focus {
    box-shadow: none;
  }

  .PhoneInputCountryIconImg {
    transform: scale(1.5);
  }
}

.PhoneInputCountrySelectArrow {
  content: "";
  width: 8px;
  height: 5px;
  background-color: #C6C6C6;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  margin-left: 5px;
  transform: none;
  border-style: none;
}

.PhoneInputInput {
  border: none;
  outline: none !important;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #29173B;
}

.PhoneInputCountry {
  margin-right: 16px;
}

.PhoneInput {
  border-bottom: 1px solid #F2F2F2;
  padding: 0 0 11px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #29173B;

  &.error {
    border-color: #C86B6D;
  }

  &.success {
    border-color: #69B5B5;
  }
}
