.base-textarea {
  position: relative;

  &--input {
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #29173B;
    padding: 8px 29px 8px 9px;
    outline: none;
    margin: 9px 0 0 0;

    &:focus {
      border-color: #29173B;
    }
  }

  &.error {
    .base-textarea--input {
      border-color: #C86B6D;
    }
  }

  &--error-text {
    position: absolute;
    left: 0;
    bottom: 0;

  }

}
