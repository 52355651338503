.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.congratsContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  svg {
    margin-bottom: 50px;
  }

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cardProduct {
  max-width: 900px;
  margin: auto;
}
