.container {
  min-height: 100vh;
  background: top / cover url('/assets/images/background/forgotPassword-background.svg') no-repeat #E5E5E5;

  .logoContainer {
    padding: 13px 0 24px;
    display: flex;
    justify-content: center;
  }
}

.carretLeft {
  position: absolute;
  top: 20px;
  left: 16px;
}
