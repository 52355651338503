.dashboardContainer {
  padding: 8px 16px 75px;
}

.baseBadge {
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #FFFFFF !important;
  top: 5px !important;
  right: 5px !important;
  filter: drop-shadow(0px 4px 8px rgba(82, 67, 170, 0.4));
}

