.container {
  padding: 0 15px;
}

.section {
  margin-bottom: 25px;

  .title {
    margin-bottom: 15px;
  }
}

.banner {
  width: 100%;
  border-radius: 20px;
  background: #fff;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(40, 24, 58, 0.08);
  position: relative;
  overflow: hidden;

  .title {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
  }

  img {
    width: 100%;
    height: auto;
  }
}

.listResources {
  margin-top: 16px;
  box-sizing: border-box;
  width: 100%;

  .resource {
    width: 48%;
    height: 142px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 16px rgba(40, 24, 58, 0.08);
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 5px 10px 0;

    img {
      margin-bottom: 5px;
    }
  }
}

.wrapSlider {
  overflow: scroll;

  .slider {
    flex-wrap: nowrap !important;
    width: max-content;

    .card {
      width: 145px;
      height: 110px;
      border-radius: 20px;
      border: solid 1px #f2f2f2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      img {
        width: 48px;
        border-radius: 50%;
        display: block;
        margin-bottom: 8px;
      }
    }

    .cardActive {
      border: solid 2px #69b5b5;
    }
  }
}

.wrapGuidebooks {
  .guidebook {
    width: 100%;
    height: 100px;
    border-radius: 20px;
    border: 1px solid #f2f2f2;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    img {
      width: 48px;
      margin-right: 15px;
    }

    .card {
      display: flex;
      align-items: center;
    }
  }
}

.giudebookDetails {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 20px;
  }

  .personInfo{
    display: flex;
    justify-content: start;
    width: 100%;
  }
}
